.Clicker {
  height: 40vmin;
  cursor: pointer;
  margin-top: 2rem;
  margin-bottom: 2rem;
  animation: clicker-spin infinite 20s linear;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

@keyframes clicker-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ClickerNumberDisplay {
  font-size: 1.5rem;
  font-weight: 500;
}
