.PowerUp {
  padding: 0.5rem;
  border: 1px solid #000;
  border-radius: 3px;
  background-color: #f3f3f3;
  cursor: pointer;
  user-select: none;
}

.Enabled {
  background-color: rgba(115, 218, 115);
  cursor: not-allowed;
}

.Available {
  background-color: rgba(196, 224, 196, 0.445);
}
