.Button {
  background-color: rgba(250, 128, 114, 0.301);
  color: rgb(153, 0, 0);
  font-weight: bold;
  border: 1px solid salmon;
  border-radius: 8px;
  outline: none;
  padding: 0.5rem;
  cursor: pointer;
}

.Button:hover {
  background-color: rgba(250, 128, 114, 0.5);
}

.Button:disabled {
  cursor: not-allowed;
}
