.Building {
  padding: 1.25rem;
  border: 1px solid rgb(190, 190, 190);
  border-radius: 6px;
  margin-bottom: 1rem;
  background-color: #fff;
  cursor: pointer;
  user-select: none;
}

.Building:hover {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.Building.Available {
  background-color: rgba(196, 224, 196, 0.445);
}

.Building.Disabled {
  cursor: not-allowed;
  background-color: rgb(197, 197, 197);
}

.Building.Hidden {
  display: none;
}

.Container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Container > div {
  flex: 1;
}

.Name {
  font-weight: bold;
}

.Description {
  font-size: 0.75rem;
}

.Count {
  font-size: 1.25rem;
  font-weight: bold;
  text-align: right;
}
